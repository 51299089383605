import React, { FC } from 'react';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat } from 'utils/currency';
import {
  getCopyDateShort,
  getCopyTime,
  getSiteName,
  P,
  Phone,
  PrintPageComponent,
  Signature,
  Signoff,
  Url,
} from './PrintPage';

export const FirstLetterBP: FC<PrintPageComponent> = ({ recovery }) => {
  const { helpPhone, storeDescription, teamName } = getOrgDetails(recovery.organizationKey);
  const siteName = getSiteName(recovery);

  return (
    <div>
      <P>Dear {recovery.recipient},</P>
      <P>
        We are writing to you as the registered owner of a vehicle with the number plate{' '}
        <b>{recovery.licensePlate}</b>. Your details have been obtained from the Motor Vehicle
        Register.
      </P>
      <P>
        At {getCopyTime(recovery)} on {getCopyDateShort(recovery)} at {siteName}, we believe a
        vehicle with your number plate acquired {getCurrencyFormat(recovery.amount)} of fuel without
        full payment being received. This could have been for a variety of reasons, for example, if
        the vehicle in the photo isn't yours, you may be a victim of stolen or doctored plates and
        it's important you let us know.
      </P>
      <P>
        We want to fix this matter for you quickly, so please call us on <Phone>{helpPhone}</Phone>{' '}
        to discuss (select option 8 from the menu). Our phones are open Monday to Friday between 9am
        and 5pm.
      </P>
      <P>
        Or alternatively, you can pay now by visiting{' '}
        <Url>alfiepay.com/{recovery.organizationKey}</Url>, using <b>{recovery.licensePlate}</b> and
        unique code <b>{recovery.debtIdentifier}</b> when directed. Or you can pop into your local{' '}
        {storeDescription} store to pay in person.
      </P>
      <P>
        Until the matter is resolved by our team or payment is received, you will be asked to
        pre-pay for fuel purchases at {storeDescription} stores.
      </P>
      <Signoff>
        Kind regards
        <Signature>{teamName}</Signature>
      </Signoff>
    </div>
  );
};
