import React, { FC } from 'react';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat } from 'utils/currency';
import {
  P,
  getSiteName,
  PrintPageComponent,
  Signature,
  Signoff,
  Url,
  getCopyTime,
  getCopyDateShort,
} from './PrintPage';

export const FirstLetterCaltex: FC<PrintPageComponent> = ({ recovery }) => {
  const { teamName } = getOrgDetails(recovery.organizationKey);
  const siteName = getSiteName(recovery);

  return (
    <div>
      <P>Dear {recovery.recipient},</P>
      <P>
        We are writing to you as the registered owner of a vehicle with number plate{' '}
        <b>{recovery.licensePlate}</b>. Your details have been obtained from the Motor Vehicle
        Register.
      </P>
      <P>
        At {getCopyTime(recovery)} on {getCopyDateShort(recovery)} at {siteName}, we believe a
        vehicle with your number plate acquired {getCurrencyFormat(recovery.amount)} of fuel without
        full payment being received. This could have been for a variety of reasons, for example, if
        the vehicle in the photo isn't yours, you may be a victim of stolen or doctored plates and
        it's important you let us know.
      </P>
      <P>
        You can pay now by visiting <Url>alfiepay.com/{recovery.organizationKey}</Url>, using{' '}
        <b>{recovery.licensePlate}</b> and unique code <b>{recovery.debtIdentifier}</b> when
        directed. Alternatively, you can pop back into {siteName} to pay in person.
      </P>
      <P>
        Until payment is received, you will be required to prepay for fuel purchases at Caltex.
        Please contact our team at {siteName} if you have any questions.
      </P>

      <Signoff>
        Kind regards,
        <Signature>{teamName}</Signature>
      </Signoff>
    </div>
  );
};
