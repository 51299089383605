import React, { FC } from 'react';
import { getCurrencyFormat } from 'utils/currency';
import {
  getCopyDate,
  getSiteName,
  P,
  PrintPageComponent,
  Signature,
  Signoff,
  Url,
} from './PrintPage';

export const SecondLetterMobil: FC<PrintPageComponent> = ({ recovery }) => {
  const siteName = getSiteName(recovery);
  const copyDate = getCopyDate(recovery);

  return (
    <div>
      <P>Dear {recovery.recipient},</P>
      <P>
        We are writing to you as the registered owner of a vehicle with number plate{' '}
        <b>{recovery.licensePlate}</b>. Your details have been obtained from the Motor Vehicle
        Register.
      </P>
      <P>
        This is a reminder that payment of {getCurrencyFormat(recovery.amount)} is still outstanding
        for fuel received at {copyDate} at {siteName}. We believe a vehicle with your number plate
        was involved and this could have been for a variety of reasons. For example, if the vehicle
        in the photo isn't yours, you may be a victim of stolen or doctored plates and it's
        important you let us know.
      </P>
      <P>
        You can pay now by visiting <Url>alfiepay.com/{recovery.organizationKey}</Url>, using{' '}
        <b>{recovery.licensePlate}</b> and unique code <b>{recovery.debtIdentifier}</b> when
        directed. Alternatively, you can pop back into {siteName} to pay in person.
      </P>
      <P>
        While this matter remains unresolved, you will be required to prepay for fuel purchases. If
        you wish to query this matter, please head back to the {siteName} store.
      </P>

      <Signoff>
        Kind regards,
        <Signature>The Team at {siteName}</Signature>
      </Signoff>
    </div>
  );
};
