import React, { FC } from 'react';
import { getCurrencyFormat } from 'utils/currency';
import {
  getCopyDateShort,
  getCopyTime,
  getSiteName,
  P,
  PrintPageComponent,
  Signature,
  Signoff,
  Url,
} from './PrintPage';

export const FirstLetterMobil: FC<PrintPageComponent> = ({ recovery }) => {
  const siteName = getSiteName(recovery);

  return (
    <div>
      <P>Dear {recovery.recipient},</P>
      <P>
        We are writing to you as the registered owner of a vehicle with number plate{' '}
        <b>{recovery.licensePlate}</b>. Your details have been obtained from the Motor Vehicle
        Register.
      </P>
      <P>
        At {getCopyTime(recovery)} on {getCopyDateShort(recovery)} at {siteName}, we believe a
        vehicle with your number plate acquired {getCurrencyFormat(recovery.amount)} of fuel without
        full payment being received. This could have been for a variety of reasons, for example, if
        the vehicle in the photo isn't yours, you may be a victim of stolen or doctored plates and
        it's important you let us know.
      </P>
      <P>
        Please pay by credit card now by visiting <Url>alfiepay.com/{recovery.organizationKey}</Url>
        , using <b>{recovery.licensePlate}</b> and unique code <b>{recovery.debtIdentifier}</b> when
        directed. Alternatively, you can pop back into {siteName} to pay in person.
      </P>
      <P>
        Until payment is received, <b>{recovery.licensePlate}</b> will be required to pre-pay for
        fuel purchases.
      </P>

      <Signoff>
        Kind regards,
        <Signature>The Team at {siteName}</Signature>
      </Signoff>
    </div>
  );
};
